<template>
  <div class="container">
    <div class="title">
      <img src="@/assets/images/p_gzh_icon@2x.png" />
      <span>问卷调查</span>
    </div>
    <!-- <h3 class="title">问卷调查</h3> -->
    <div class="tips">
      <p class="tips_p1">问卷说明：</p>
      <p class="tips_p2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;尊敬的骨科医师您好，为进一步规范骨科手术切口操作，预防切口并发症，助力围术期切口的科学管理，诚邀您参与以下问卷调研，本调研旨在了解您目前对骨科手术切口皮肤层次的处理现状，感谢您的支持与参与！</p>
    </div>

    <div class="block">
      <p class="subject">1.请选择您所任职的医院级别？</p>
      <van-radio-group class="radio_group" v-model="que1">
        <van-radio class="radio" name="三级甲等" checked-color="#ca001b" icon-size="20px">三级甲等</van-radio>
        <van-radio class="radio" name="三级乙等" checked-color="#ca001b" icon-size="20px">三级乙等</van-radio>
        <van-radio class="radio" name="二级甲等" checked-color="#ca001b" icon-size="20px">二级甲等</van-radio>
        <van-radio class="radio" name="二级乙等" checked-color="#ca001b" icon-size="20px">二级乙等</van-radio>
        <van-radio class="radio" name="一级甲等" checked-color="#ca001b" icon-size="20px">一级甲等</van-radio>
        <van-radio class="radio" name="一级乙等" checked-color="#ca001b" icon-size="20px">一级乙等</van-radio>
        <van-radio class="radio" name="其他医院" checked-color="#ca001b" icon-size="20px">其他医院</van-radio>
      </van-radio-group>
    </div>

    <div class="block">
      <p class="subject">2.请选择您所从事的骨科专业？</p>
      <van-radio-group class="radio_group" v-model="que2">
        <van-radio class="radio" name="创伤" checked-color="#ca001b" icon-size="20px">创伤</van-radio>
        <van-radio class="radio" name="关节" checked-color="#ca001b" icon-size="20px">关节</van-radio>
        <van-radio class="radio" name="脊柱" checked-color="#ca001b" icon-size="20px">脊柱</van-radio>
        <van-radio class="radio" name="手外/显微" checked-color="#ca001b" icon-size="20px">手外/显微</van-radio>
        <van-radio class="radio" name="骨肿瘤" checked-color="#ca001b" icon-size="20px">骨肿瘤</van-radio>
        <van-radio class="radio" name="运动医学" checked-color="#ca001b" icon-size="20px">运动医学</van-radio>
        <van-radio class="radio" name="骨科综合" checked-color="#ca001b" icon-size="20px">骨科综合</van-radio>
      </van-radio-group>
    </div>

    <div class="block">
      <p class="subject">3.您目前选择的皮肤缝合主要采取的方法是？</p>
      <van-radio-group class="radio_group" v-model="que3" @change="que3Change">
        <van-radio class="radio" name="普通间断缝合" checked-color="#ca001b" icon-size="20px">普通间断缝合</van-radio>
        <van-radio class="radio" name="普通连续缝合" checked-color="#ca001b" icon-size="20px">普通连续缝合</van-radio>
        <van-radio class="radio" name="皮内连续缝合" checked-color="#ca001b" icon-size="20px">皮内连续缝合</van-radio>
        <van-radio class="radio" name="改良褥式缝合" checked-color="#ca001b" icon-size="20px">改良褥式缝合</van-radio>
        <van-radio class="radio" name="不单独缝合皮肤（使用皮肤减张装置/皮肤胶水）" checked-color="#ca001b" icon-size="20px">不单独缝合皮肤(使用皮肤减张装置/皮肤胶水)</van-radio>
        <van-radio class="radio" name="不同部位有不同的缝合方法" checked-color="#ca001b" icon-size="20px">不同部位有不同的缝合方法</van-radio>
        <van-radio class="radio" name="其他" checked-color="#ca001b" icon-size="20px">其他<input type="text" class="input" v-model="que3Other"></van-radio>
      </van-radio-group>
    </div>

    <div class="block">
      <p class="subject">4.您目前缝合皮肤主要选用的材料是？(多选)</p>
      <van-checkbox-group class="radio_group" v-model="que4">
        <van-checkbox class="radio" name="丝线" checked-color="#ca001b" icon-size="20px">丝线</van-checkbox>
        <van-checkbox class="radio" name="皮钉" checked-color="#ca001b" icon-size="20px">皮钉</van-checkbox>
        <van-checkbox class="radio" name="可吸收缝线" checked-color="#ca001b" icon-size="20px">可吸收缝线</van-checkbox>
        <van-checkbox class="radio" name="单股不可吸收缝线" checked-color="#ca001b" icon-size="20px">单股不可吸收缝线</van-checkbox>
        <van-checkbox class="radio" name="免打结缝线（倒刺线）" checked-color="#ca001b" icon-size="20px">免打结缝线（倒刺线）</van-checkbox>
        <van-checkbox class="radio" name="金属线" checked-color="#ca001b" icon-size="20px">金属线</van-checkbox>
        <van-checkbox class="radio" name="蛋白线" checked-color="#ca001b" icon-size="20px">蛋白线</van-checkbox>
        <van-checkbox class="radio" name="皮肤拉链/拉扣" checked-color="#ca001b" icon-size="20px">皮肤拉链/拉扣</van-checkbox>
        <van-checkbox class="radio" name="皮肤减张闭合器" checked-color="#ca001b" icon-size="20px">皮肤减张闭合器</van-checkbox>
        <van-checkbox class="radio" name="皮肤胶水" checked-color="#ca001b" icon-size="20px">皮肤胶水</van-checkbox>
        <van-checkbox class="radio" name="不清楚，随意选择" checked-color="#ca001b" icon-size="20px">不清楚，随意选择</van-checkbox>
      </van-checkbox-group>
    </div>

    <div class="block">
      <p class="subject">5.术后您是否会选择其他哪些方式处理伤口？(多选)</p>
      <van-checkbox-group class="radio_group" v-model="que5" @change="que5Change">
        <van-checkbox class="radio" name="拆除丝线" checked-color="#ca001b" icon-size="20px">拆除丝线</van-checkbox>
        <van-checkbox class="radio" name="拆除可吸收缝线" checked-color="#ca001b" icon-size="20px">拆除可吸收缝线</van-checkbox>
        <van-checkbox class="radio" name="拆除其他不可吸收缝线" checked-color="#ca001b" icon-size="20px">拆除其他不可吸收缝线</van-checkbox>
        <van-checkbox class="radio" name="拆皮钉" checked-color="#ca001b" icon-size="20px">拆皮钉</van-checkbox>
        <van-checkbox class="radio" name="拆除皮肤减张闭合器" checked-color="#ca001b" icon-size="20px">拆除皮肤减张闭合器</van-checkbox>
        <van-checkbox class="radio" name="更换辅料" checked-color="#ca001b" icon-size="20px">更换辅料</van-checkbox>
        <div class="otherRadio">
          <van-checkbox class="radio" name="其他" checked-color="#ca001b" icon-size="20px">其他</van-checkbox><input type="text" class="input" v-model="que5Other">
        </div>
      </van-checkbox-group>
    </div>

    <div class="block">
      <p class="subject">6.您是否处理过以下皮肤相关的切口并发症？(多选)</p>
      <van-checkbox-group class="radio_group" v-model="que6">
        <van-checkbox class="radio" name="切口渗血，渗液" checked-color="#ca001b" icon-size="20px">切口渗血，渗液</van-checkbox>
        <van-checkbox class="radio" name="切口周围肿胀" checked-color="#ca001b" icon-size="20px">切口周围肿胀</van-checkbox>
        <van-checkbox class="radio" name="切口周围水泡" checked-color="#ca001b" icon-size="20px">切口周围水泡</van-checkbox>
        <van-checkbox class="radio" name="切口周围瘀斑" checked-color="#ca001b" icon-size="20px">切口周围瘀斑</van-checkbox>
        <van-checkbox class="radio" name="切口愈合不良" checked-color="#ca001b" icon-size="20px">切口愈合不良</van-checkbox>
        <van-checkbox class="radio" name="切口感染" checked-color="#ca001b" icon-size="20px">切口感染</van-checkbox>
      </van-checkbox-group>
    </div>

    <div class="block">
      <p class="subject">7.对于皮肤切口的关闭，您最关注点的是什么，欢迎留言！</p>
      <van-field
        v-model="que7"
        class="field"
        rows="2"
        autosize
        type="textarea"
        placeholder="请输入留言"
      />
    </div>

    <div class="submit" @click="submit">提交</div>
  </div>
</template>

<script>
import utils from "../../common/utils/utils";
import { mapState } from 'vuex'
export default {
  name: 'Questionnaire',
  data() {
    return {
      que1: '',
      que2: '',
      que3: '',
      que3Other: '',
      que4: [],
      que5: [],
      que5Other: '',
      que6: [],
      que7: ''
    }
  },
  computed: {
    ...mapState(['userInfo'])
  },
  methods: {
    que3Change(val) {
      if(val != "其他") {
        this.que3Other = '';
      }
    },
    que5Change(val) {
      let index = val.indexOf("其他");
      if(index == -1) {
        this.que5Other = "";
      }
    },
    submit: utils.debounce(function() {
      let index = this.que5.indexOf('其他');
      if(index != -1) {
        this.que5[index] = this.que5[index] + ':' + this.que5Other;
      }

      let data = {
        CustomerId: this.userInfo.Id,
        HospitalLevel: this.que1,
        Major: this.que2,
        Method: this.que3 != '其他' ? this.que3 : this.que3 + ':' + this.que3Other,
        Material: this.que4.join(';'),
        Way: this.que5.join(';'),
        Complication: this.que6.join(';'),
        Focus: this.que7
      }
      
      if(this.que1 || this.que2 || this.que3 || this.que4.length > 0 || this.que5.length > 0 || this.que6.length > 0 || this.que7) {
        this.$axios.post("/Api/Api/Web/QuestionnaireSurvey/Add", data).then((res) => {
          if (res.RetCode == '10000') {
            this.que1 = '';
            this.que2 = '';
            this.que3 = '';
            this.que3Other = '';
            this.que4 = [];
            this.que5 = [];
            this.que5Other = '';
            this.que6 = [];
            this.que7 = '';
            this.$dialog.alert({
              message: "再次感谢您的参与和支持，祝您身体健康，工作顺利，万事顺意！",
            });
          } else {
            this.$toast(res.RetMsg);
          }
        });
      } else {
        this.$dialog.alert({
          message: "提交内容不能为空！",
        });
      }
    }, 1000)
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  min-height: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    margin: 20px 0;
    color: #CA001B;
    font-weight: bolder;
    img {
      width: 25px;
      height: 25px;
      margin-right: 8px;
      display: block;
    }
  }
  .tips {
    margin-bottom: 10px;
    .tips_p1 {
      font-size: 15px;
      color: #333333;
      font-weight: bold;
      line-height: 26px;
    }
    .tips_p2 {
      font-size: 12px;
      color: #ababab;
      line-height: 16px;
    }
  }
  .block {
    margin-bottom: 10px;
    .subject {
      font-size: 15px;
      color: #333333;
      margin-bottom: 8px;
    }
    .radio_group {
      margin-left: 13px;
    }
    .radio {
      margin-bottom: 4px;
    }
    .otherRadio {
      display: flex;
    }
    .input {
      width: 200px;
      border: none;
      border-bottom: 1px solid #000000;
      margin-left: 4px;
      height: 15px;
      color: #333333;
    }
    .field {
      border: 1px solid #cccccc;
      border-radius: 8px;
    }
  }
  .submit {
    width: 100%;
    height: 48px;
    line-height: 48px;
    color: #ffffff;
    font-size: 16px;
    background: #ca001b;
    border-radius: 4px;
    border: none;
    text-align: center;
    margin: 30px auto 30px;
  }
}

/deep/ .van-radio {
  font-size: 14px;
}
/deep/ .van-checkbox {
  font-size: 14px;
}
</style>